@import 'app/variables.scss';

.Timeline {
  --viewer-width: 275px;
  --timeline-item-muted-opacity: 0.66;
  --timeline-item-outline-width: 2px;
  --timeline-item-outline-offset: 1px;

  position: relative;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: hidden;
}

.Timeline__actions {
  margin-bottom: 2.5 * $aleph-grid-size;
}

.Timeline__main,
.Timeline__viewer {
  position: absolute;
  top: 0;
  height: 100%;
  overflow-y: auto;
}

.Timeline__main {
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: $aleph-content-padding;
}

.Timeline--selected .Timeline__main {
  right: var(--viewer-width);
}

.Timeline__viewer {
  right: 0;
  width: var(--viewer-width);
  padding: $aleph-grid-size;

  border-left: 1px solid $aleph-border-color;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}
