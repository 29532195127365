@import 'app/variables.scss';

.TimelineChartLabels {
  position: sticky;
  top: -1 * $aleph-content-padding;
  z-index: 1;
  width: 100%;
  height: 3 * $aleph-grid-size;
  margin-bottom: $aleph-grid-size;
}

.TimelineChartLabels__label {
  position: absolute;
  left: calc(
    var(--timeline-chart-label-start-day) * var(--timeline-chart-day-width)
  );
  /* prettier-ignore */
  width: calc(
    (var(--timeline-chart-label-end-day) - var(--timeline-chart-label-start-day)) * var(--timeline-chart-day-width)
  );

  padding: 0 $aleph-grid-size;
  line-height: 3 * $aleph-grid-size;

  color: $aleph-greyed-text;
  background-color: white;
  border-bottom: 1px solid $aleph-border-color;
  border-left: 1px solid $aleph-border-color;
  border-right: 1px solid $aleph-border-color;
}

.TimelineChartLabels__text {
  position: sticky;
  left: 0;
}
