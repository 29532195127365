@import 'app/variables.scss';
@import 'app/mixins.scss';

.FileImport {
  &__file-name.#{$bp-ns}-button {
    margin: 0 0, $aleph-grid-size * 2, 0;
    @include rtlSupportInvertedProp(margin, left, -$aleph-grid-size, null);

    &:hover {
      text-decoration: underline !important;
      background: none !important;
    }
  }

  &__placeholder {
    padding: 20px;
    border: 1px dashed $aleph-border-color;
    cursor: pointer;
    transition: background 0.1s ease-in-out;

    &:hover {
      background: $light-gray3;
    }
  }
}
