@import 'app/variables.scss';
@import 'app/mixins.scss';

@mixin small {
  @media screen and (max-width: $aleph-screen-sm-max-width) {
    @content;
  }
}

.FacetedEntitySearch {
  .total-count-span {
    display: inline-flex;
    @include rtlSupportInvertedProp(padding, left, 0.5em, null);
    font-size: 1.1em;
    font-weight: bold;
  }

  .total-count {
    display: flex;
    padding: 0 $aleph-grid-size;
    -ms-flex-align: center;
    align-items: center;
    height: 3em !important;
  }

  .total-icon {
    @include rtlSupportInvertedProp(margin, right, 0.5em, null);
  }

  .facets {
    display: none;
    cursor: pointer;
    margin-top: 0.5em;
  }

  &__controls {
    .QueryTags {
      margin-bottom: $aleph-grid-size * 0.5;

      .#{$bp-ns}-tag {
        @include rtlSupportInvertedProp(margin, right, 4px, 0);
      }
    }
  }

  .Facets {
    margin-bottom: $aleph-grid-size;
  }

  .SidePane .SearchFieldSelect {
    display: block;
    margin-bottom: $aleph-grid-size * 2;
  }

  .SearchFieldSelect {
    @include small {
      display: none;
    }
  }

  .DualPane__inner-container {
    position: relative;
  }

  .#{$bp-ns}-drawer {
    box-shadow: none;
  }

  .#{$bp-ns}-overlay {
    width: $aleph-sidebar-width;
  }

  &__side-placeholder.SidePane {
    height: auto;
    position: relative;
    transition: width 120ms linear;
    min-height: 100vh;
    width: $aleph-sidebar-width;

    .collapsed & {
      width: 0;
      padding: 0;
    }
  }

  &__expand-toggle {
    $toggle-width: $aleph-grid-size * 3;
    @include small {
      display: none;
    }

    position: fixed;
    top: 30px;
    width: $toggle-width;
    z-index: 100;
    display: flex;
    flex-direction: column;
    height: 100vh;
    transform: translateX($aleph-sidebar-width - $toggle-width * 0.5);

    .collapsed & {
      transform: translateX(0);
      left: $aleph-grid-size;
      opacity: 1;
    }

    &__button.#{$bp-ns}-button.#{$bp-ns}-outlined {
      background: white;
      border-radius: 15px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        background: $light-gray3;
      }
    }
  }

  &__mobile-expand-toggle {
    margin-bottom: $aleph-grid-size * 2;
  }

  .#{$bp-ns}-divider {
    margin: 0 0 $aleph-grid-size;
  }
}
