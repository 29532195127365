@import 'app/mixins.scss';

.AuthenticationScreen {
  width: 28em !important;

  .inner {
    margin-top: 1em;
    @include rtlSupportInvertedProp(margin, right, 1em, 1em);
  }

  .menu-divider {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .signin-button {
    @include rtl(float, right, left);
  }

  .hide {
    display: none;
  }

  .link-box {
    margin-top: 2em;
    margin-bottom: 2em;
    // margin-left: 8.5em;
  }
}
