@import 'app/variables';

.TimelineListItem {
  --background-opacity: 0.1;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.TimelineListItem > td:first-child {
  border-left: 4px solid var(--timeline-item-color);
  border-top-left-radius: $pt-border-radius;
  border-bottom-left-radius: $pt-border-radius;
}

.TimelineListItem > td:first-child::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;

  background-color: var(--timeline-item-color);
  opacity: var(--background-opacity);
  border-radius: $pt-border-radius;
}

.TimelineListItem > td {
  padding: var(--timeline-list-padding);
}

.TimelineListItem__date {
  width: 1%;
  white-space: nowrap;
}

.TimelineListItem__property {
  width: 8rem;
  color: $aleph-greyed-text;
}

// Double class is required in order to increase specificity over the
// default Blueprint table styles.
.TimelineListItem__actions.TimelineListItem__actions {
  text-align: right;
}

.TimelineListItem__actions > * {
  position: relative;
  opacity: 0;
}

.TimelineListItem--muted {
  opacity: var(--timeline-item-muted-opacity);
}

.TimelineListItem:where(:hover),
.TimelineListItem:where(:focus-within) {
  --background-opacity: 0.15;
}

.TimelineListItem:where(:hover) .TimelineListItem__actions > *,
.TimelineListItem:where(:focus-within) .TimelineListItem__actions > * {
  opacity: 1;
}

.TimelineListItem:focus {
  outline: var(--timeline-item-outline-width) solid var(--timeline-item-color);
  outline-offset: var(--timeline-item-outline-offset);

  // `border-radius` doesn't have an effect on table rows, but it does
  // affect the radius of the outline (in newer browser).
  border-radius: $pt-border-radius;
}

.TimelineListItem--selected {
  --background-opacity: 0.2;
}
