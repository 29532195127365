@import 'app/variables.scss';

.TimelineChartGrid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.TimelineChartGrid__line {
  position: absolute;
  left: calc(
    var(--timeline-chart-grid-line-start-day) * var(--timeline-chart-day-width)
  );
  height: 100%;
  width: 0;
  border-right: 1px dotted $aleph-border-color;
}

.TimelineChartGrid__line--main {
  border-right-style: solid;
  border-right-width: 2px;
  transform: translateX(-1px);
}
