@import 'app/variables.scss';

$transition-duration: 0.3s;
$inner-padding: 15px;

.InvestigationHeading {
  background: rgba($aleph-investigation-theme, 0.7);
  transition: all $transition-duration ease-in-out;
  overflow: hidden;
  max-height: none;
  border-radius: $aleph-border-radius;
  margin-bottom: $aleph-content-padding;

  &__inner-container {
    padding: $aleph-grid-size * 2 $inner-padding 0;
    transition: padding $transition-duration ease-in-out;

    .#{$bp-ns}-text-muted,
    .CollectionHeading__title,
    .CollectionInfo__item .value {
      color: white;
    }
  }

  .CollectionHeading__title {
    margin-bottom: $aleph-grid-size * 2;
  }

  .text-markdown {
    margin: 0 0 $aleph-grid-size 0 !important;
  }

  .text-markdown p {
    margin-bottom: 5px !important;
  }

  &__divider {
    border-top: 1px solid rgba(white, 0.7);
    margin: auto;
    width: 0;
    transition: width $transition-duration ease-in-out, margin 1s ease-in-out;

    .metadata-shown & {
      width: 100%;
      margin-top: 0;
      margin-bottom: $aleph-grid-size;
    }
  }

  &__metadata {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-in-out;

    .metadata-shown & {
      max-height: 10000px;
    }

    .CollectionInfo__item .key {
      color: white !important;
    }

    &__inner-container {
      margin: $inner-padding 0;
      padding: $aleph-grid-size;
      border-radius: 5px;
      background: rgba(#fff, 0.15);
    }
  }

  &__metadata-toggle.#{$bp-ns}-button {
    width: 100%;
    transition: height $transition-duration ease-in-out;
    overflow: hidden;
    background: rgba($white, 0.1) !important;

    &:hover {
      background: rgba($white, 0.2) !important;
    }

    .#{$bp-ns}-icon {
      color: white !important;
    }
  }

  .CollectionHeading {
    margin: 0;

    &__subheading {
      transition: max-height $transition-duration ease-in-out;
      overflow: hidden;
    }

    &__title {
      transition: font-size $transition-duration ease-in-out,
        font-weight $transition-duration ease-in-out;

      .#{$bp-ns}-icon {
        padding-right: 3px;
      }
    }
  }

  .CollectionStatus {
    padding: $aleph-grid-size;
    border-radius: 5px;
    font-size: $pt-font-size-small;
    margin: $aleph-grid-size * 2 0 $aleph-grid-size;

    .#{$bp-ns}-heading {
      font-size: 14px !important;
      margin-bottom: 0 !important;
    }
  }

  .CollectionLink,
  .CategoryLink {
    color: white !important;
  }

  .CollectionInfo__item .value {
    font-weight: 500;
  }
}
